<div class='bems-loader-wrapper' id='bems-loader-wrapper'>
    <div class='bems-loader'>
        <svg class='circular' viewbox='25 25 50 50'>
            <circle class='path' cx='50' cy='50' fill='none' r='20' stroke-miterlimit='10' stroke-width='7'></circle>
        </svg>
    </div>
</div>
<div class="container-fluid header-line-hight-top">
</div>
<div class="container-fluid bg-nav bems-header sticky-top">
    <div class="container">
        <nav id="navv" class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/home/status-overview">
                <img class="logo-sm" alt="brand" src="assets/images/cusc_logo.png">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#bems-navbar-collapse"
                aria-controls="bems-navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="bems-navbar-collapse">
                <ul class="navbar-nav mr-auto">
                    <li>
                        <a class="nav-button" href="https://ls.ene.cusmart.chula.ac.th" target="_blank">Main Map</a>
                    </li>
                    <li class="nav-item active">
                        <a class="nav-button" routerLink="/home/load-profile" routerLinkActive="active-link"
                            data-toggle="collapse" data-target=".navbar-collapse.show">Load Profile</a>
                    </li>
                    <li>
                        <a class="nav-button" routerLink="/home/new-dashboard" routerLinkActive="active-link"
                            data-toggle="collapse" data-target=".navbar-collapse.show">Dashboard</a>
                    </li>
                    <li>
                        <a class="nav-button" routerLink="/home/system" routerLinkActive="active-link"
                            data-toggle="collapse" data-target=".navbar-collapse.show">Energy Award</a>
                    </li>
                    <li>
                        <a class="nav-button" routerLink="/home/demand-response" routerLinkActive="active-link"
                            data-toggle="collapse" data-target=".navbar-collapse.show">Demand Response</a>
                    </li>
                    <li>
                        <a class="nav-button" href="https://ls.ene.cusmart.chula.ac.th/p2p/">P2P Trading</a>
                    </li>
                    <li class="nav-item bg-link">
                        <div class="dropdown">
                            <button type="button" class="nav-button dropdown-toggle" data-toggle="dropdown"
                                aria-expanded="false" data-offset="10,20">
                                Link to others
                            </button>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="http://cen050.ene.cusmart.chula.ac.th/">Witthayanives</a>
                                <a class="dropdown-item"
                                    href="http://cen060.ene.cusmart.chula.ac.th/">Chulachakkrapong</a>
                                <a class="dropdown-item"
                                    href="http://cen053.ene.cusmart.chula.ac.th/">Boromrajakumari</a>
                                <a class="dropdown-item" href="http://cen077.ene.cusmart.chula.ac.th/">Chamchuri 9</a>
                                <a class="dropdown-item" href="http://cen047.ene.cusmart.chula.ac.th/">Chamchuri 4</a>
                                <a class="dropdown-item" href="https://cen087.ene.cusmart.chula.ac.th/">Sport
                                    Complex</a>

                            </div>
                        </div>
                    </li>
                    <li>
                        <a class="nav-button" href="https://cusmart.chula.ac.th/#/commu">Back to Community</a>
                    </li>
                </ul>
                <ul class="nav justify-content-end" style="display: flex; align-items: center;">
                    <li id="mobile">
                        <a *ngIf="privilege" class="nav-button">
                            <i *ngIf="bmPage == 'notsamepage'" (click)="bookmark()" class="material-icons md-18"
                                title="Set favaourite page"
                                style="cursor:pointer; display: flex; align-items: center;">star_border</i>
                            <i *ngIf="bmPage == 'samepage'" (click)="bookmark()" class="material-icons md-18"
                                title="Set favaourite page"
                                style="cursor:pointer; display: flex; align-items: center;">star</i>
                            <i *ngIf="bmStatus == 'havelink' && bmPage == 'notsamepage'" (click)="goto_bookmark()"
                                class="material-icons md-18" title="Go to favaourite page"
                                style="cursor:pointer; display: flex; align-items: center;">bookmark</i>
                        </a>
                    </li>
                    <li id="mobile" *ngIf="(privilege && user_role)">
                        <a class="nav-button" [matMenuTriggerFor]="menu" type="button">
                            <span style="display: flex; align-items: center;">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 0 1 4.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 0 1-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 0 1 1.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 0 1 2.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 0 1 2.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 0 1 1.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 0 1-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 0 1 8.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 0 0 1.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 0 0 .52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 0 0-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 0 0-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 0 0-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 0 0-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 0 0 .52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 0 0 1.255-.52l.094-.319z" />
                                    <path fill-rule="evenodd"
                                        d="M8 5.754a2.246 2.246 0 1 0 0 4.492 2.246 2.246 0 0 0 0-4.492zM4.754 8a3.246 3.246 0 1 1 6.492 0 3.246 3.246 0 0 1-6.492 0z" />
                                </svg>
                                <span>&nbsp;Admin Panel</span>
                                <i class='material-icons'>arrow_drop_down</i>
                            </span>
                        </a>
                        <mat-menu #menu="matMenu">
                            <a mat-menu-item href="#/admin/system-status">System status</a>
                            <a mat-menu-item href="#/admin/compare-graph">Compare Graph</a>
                            <a mat-menu-item href="#/admin/report">Report</a>
                            <a *ngIf="user_role != 'pw_user'" mat-menu-item href="#/admin/user-setting">User
                                Management</a>
                            <a *ngIf="user_role != 'pw_user'" mat-menu-item href="#/admin/profile-setting">Profile
                                Setting</a>
                            <a mat-menu-item href="#/admin/new-dashboard">Dashboard Setting</a>
                            <a mat-menu-item href="#/admin/ranking-setting">Ranking Setting</a>
                            <a *ngIf="user_role != 'pw_user'" mat-menu-item href="#/admin/point-structure-setting">Point
                                Structure Setting</a>
                            <a *ngIf="user_role != 'pw_user'" mat-menu-item href="#/admin/floor-setting">Floor
                                Setting</a>
                            <a *ngIf="user_role != 'pw_user'" mat-menu-item href="#/admin/room-setting">Room Setting</a>
                            <a *ngIf="user_role != 'pw_user'" mat-menu-item href="#/admin/device-management">Device
                                Management</a>
                            <a mat-menu-item href="#/admin/notification-setting">Notification Setting</a>
                            <a mat-menu-item href="#/admin/check-data-notification-setting">Check Data & Notification
                                Setting</a>
                            <a mat-menu-item href="#/admin/maintenance-check-data">Maintenance Check Data</a>
                            <a mat-menu-item href="#/admin/profile-power-setting">Profile Power Setting</a>
                            <a mat-menu-item href="#/admin/profile-energy-setting">Profile Energy Setting</a>
                            <a mat-menu-item href="#/admin/structure-power-setting">Structure Power Setting</a>
                            <a mat-menu-item href="#/admin/structure-energy-setting">Structure Energy Setting</a>
                            <a mat-menu-item href="#/admin/demand-control-center">Demand Control Center</a>
                            <a mat-menu-item href="#/admin/real-time-monitor">Real time monitor</a>
                        </mat-menu>
                    </li>
                    <li *ngIf="privilege">
                        <a class="nav-button" type="button" routerLink="/home/user-setting">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person" fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            </svg>
                            <span>&nbsp;{{user}}</span>
                        </a>
                    </li>
                    <li *ngIf="privilege">
                        <a class="nav-button" type="button" (click)="logOut()">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-box-arrow-in-right"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                    d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                                <path fill-rule="evenodd"
                                    d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                            </svg>
                            <span>&nbsp;Logout</span>
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="!privilege">
                        <a class="nav-button" type="button" (click)="logInForm()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z" />
                                <path fill-rule="evenodd"
                                    d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                            </svg>
                            <span>&nbsp;Login</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-button" type="button" data-toggle="modal" data-target="#aboutModal">About</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="aboutModal" tabindex="-1" role="dialog" aria-labelledby="aboutModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body box-about">
                <div class="row box-about-1">
                    <div class="col-12 col-md-6 col-lg-6 box-about-1-logo-1">
                        <img class="aboutCuscLogoImg" src="assets/images/cusc-Logo.png">
                    </div>
                    <div class="col-12 mt-4 col-md-6 col-lg-6 box-about-1-logo-2">
                        <img class="aboutERCLogoImg" src="assets/images/ERC-Logo.png">
                        <span>ได้รับการสนับสนุนงานงบประมาณจากกองทุนพัฒนาไฟฟ้า สำนักงานคณกรรมการกำกับกิจการพลังงาน พ.ศ. 2562</span>
                    </div>
                </div>
                <div class="row box-about-2">
                    <div class="col-12 col-md-5 col-lg-5 box-about-2-details">
                        <span style="inline-size: max-content">ChulaSmart: <b>Smart Energy (Chamchuri 9)</b></span>
                        <span>version 0.0.6</span>
                        <span class="m-1">launched on: 6 February 2023</span>
                    </div>
                    <div class="col-12 col-md-7 col-lg-7 box-about-2-power">
                        <span>Powered by:</span>
                        <img class="aboutChulaLogoImg" src="assets/images/chula-Logo.png">
                        <img class="aboutERILogoImg" src="assets/images/ERI-Logo.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>